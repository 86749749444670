<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="erp/user" :height="window.height - 310" :columns="columns"  @edit="openModalForEdit">
             <template #head(role_id)="data">
                <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                    {{ data.label }}
                    <template v-if="data.field.sortable">
                    <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                    <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                    </template>
                </div>
                <b-form-select :options="rolesList" v-model="role_id" value-field="id"
                    text-field="name" @change="search(role_id)">
                    <template #first>
                        <b-form-select-option :value="''" ></b-form-select-option>
                    </template>
                </b-form-select>
            </template>
            <template #head(email)="data">
                <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                    {{ data.label }}
                    <template v-if="data.field.sortable">
                    <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                    <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                    </template>
                </div>
                <b-form-select :options="usersList" v-model="email" value-field="email"
                               text-field="email" @change="searchEmail(email)">
                    <template #first>
                        <b-form-select-option :value="''" ></b-form-select-option>
                    </template>
                </b-form-select>
            </template>
            <template v-slot:cell(role_id)='row'>
                <span v-for="(item,index) in rolesList" :key="index">
                    <span v-if="row.item.role_id == item.id">{{ item.name }}</span>
                </span>
            </template>
            <template v-slot:cell(pipelines)='row'>
                  <div v-for="(item,index) in pipelineList" :key="index">
                    <div v-for="userPipeline in row.item.pipelines">
                      <div v-if="userPipeline.pipeline.id === item.id" style="margin-right: 8px">{{ userPipeline.pipeline.name }} - {{ userPipeline.role }}</div>
                    </div>
                  </div>
            </template>
            <template v-slot:cell(last_login_at)='row'>
                {{ formatWithCurrentTimezone(row.item.last_login_at) }}
            </template>
            <template #button>
                <b-button variant="info" class="mr-1" @click="openModalForCreate">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">{{ $t("Users") }}</span>
                </b-button>
            </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="user-modal"
      centered
      :title="$t(title)"
      v-model="isShow"
      size="lg"
      hide-footer
    >
        <b-card no-body>
            <b-card-body>
                <validation-observer ref="rules" v-slot="{ invalid }">
                    <b-row>
                        <b-col lg="12" v-if="mode === 'CREATE'">
                          <validation-provider :name="$t('Native User')" rules="required">
                            <b-form-group
                              :label="$t('Native User')"
                              label-for=""
                              slot-scope="{ valid, errors }"
                            >
                              <b-form-select v-model="info.user_id" value-field="id" text-field="fullName" :options="nativeUsersList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                              <b-form-invalid-feedback>
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col lg="12">
                            <validation-provider :name="$t('Role')" rules="required">
                                <b-form-group
                                :label="$t('Role')"
                                label-for=""
                                slot-scope="{ valid, errors }"
                                >
                                    <b-form-select v-model="info.role_id" value-field="id" text-field="name" :options="rolesList" :state="errors[0] ? false : valid ? true : null"></b-form-select>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                <div>
                    <div class="w-100 d-flex justify-content-end">
                        <b-button
                            variant="info"
                            class="btn mr-2"
                            @click="isShow=false;$refs.advanceTable.loadList()"
                        >
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button
                            variant="primary"
                            class="btn"
                            @click="save"
                            :disabled="invalid"
                        >
                            {{ $t('Save') }}
                        </b-button>
                    </div>
                </div>
                </validation-observer>
            </b-card-body>
        </b-card>
    </b-modal>
</div>
</template>
<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@configs/apis";
import vSelect from "vue-select";
import { formatWithCurrentTimezone } from "./utils/time";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";

export default {
    mixins: [Prompt],
    components:{vSelect,AdvanceTable},
    data(){
        return{
            window: {
                width: 0,
                height: 0
            },
            title:'Add User',
            isShow:false,
            info:{},
            columns:[
                { key: 'name', modelName:'nativeUser.fullName', label: 'User Name', filtertype: "input", sortable: false},
                { key: 'email', modelName:'nativeUser.email', label: 'Email', sortable: false},
                { key: 'role_id', modelName:'roleId', label: 'Role', sortable: false},
                { key: 'last_login_at', modelName:'last_login_at', label: 'Last Login', sortable: false},
            ],
            role_id:'',
            sortBy: "",
            sortDesc: true,
            rolesList:[],
            usersList:[],
            pipelineList: [],
            nativeUsersList: [],
            currencyList:[],
            languageList:[],
            email:'',
            mode: 'CREATE'
        }
    },
    mounted(){
        this.getNativeUsers()
        this.getUsers()
        this.getRoles()
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods:{
      formatWithCurrentTimezone,
        getFormat(t) {
            let date = new Date(+new Date(t) + 8 * 3600 * 1000)
            let seperator = "-"
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            let strDate = date.getDate();
            let hours = date.getHours();
            let mm = date.getMinutes();
            let s = date.getSeconds();
            if(month >= 1 && month <= 9){
                month = "0" + month
            }
            if(strDate >= 0 && strDate <= 9){
                strDate = "0" + strDate
            }
          return year + seperator + month + seperator + strDate + ' ' + hours + ':' + mm + ':' + s
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        clearData(){
            this.email = ''
            this.role_id= ''
        },
        search(val){
            this.$refs.advanceTable.loadList({'roleId':val});
        },
        searchEmail(val){
            this.$refs.advanceTable.loadList({'nativeUser.email':val});
        },
        openModalForCreate(){
            Object.keys(this.info).forEach(key => (this.info[key] = ''));
            this.$refs.advanceTable.loadList()
            this.isShow = true
            this.mode = 'CREATE';
            this.title = 'Add User'
        },
        openModalForEdit(item){
            this.title = 'Update User - ' + item.name
            this.isShow = true
            this.mode = 'EDIT';
            this.info = item
            this.username = item.name
        },
        async save(){
            if (this.mode === 'CREATE') {
                try {
                    const response = await axios.post(
                        apis.erpPutPostUser,
                        this.info
                    )
                    if (response.error) {
                        this.promptError(response.error);
                        return false;
                    }
                    this.isShow = false
                    this.promptInfo('Add User Success');

                    await this.$refs.advanceTable.loadList();
                }catch (e) {
                  this.promptError(e);
                }
            } else if (this.mode === 'EDIT') {
                try {
                    const response = await axios.put(
                        apis.erpPutUpdateUser+'/'+this.info.id,
                        this.info
                    )
                    if (response.error) {
                      this.promptError(response.error);
                        return false;
                    }
                    this.isShow = false
                    this.promptInfo('Update User Success');
                    await this.$refs.advanceTable.loadList();
                } catch (e) {
                  this.promptError(e);
                }
            }
        },
        async getRoles(){
            try {
                const response = await axios.get(
                    apis.erpGetRoles,
                    {}
                )
                this.rolesList = response.data.data.data
            } catch (e) {
                console.log(e);
            }
        },
        async getUsers(){
            try {
                const response = await axios.get(
                    apis.erpGetUsers,
                    {}
                )
                this.usersList = response.data.data.data
            } catch (e) {
                console.log(e);
            }
        },

        async getNativeUsers(){
          try {
            const response = await axios.get(
              apis.getUsers,
              {}
            )
            this.nativeUsersList = response.data.data.data
          } catch (e) {
            console.log(e);
          }
        }
    }
}
</script>